export const COLORS = {
  FONT: '#222',
};

export const DELAYTIME = {
  FLOW: 0.5, // *
  TEXT: 700,
};

export const DURATION = {
  FLOW: 1500,
  TEXT: 500,
};
