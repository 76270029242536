export const COLORS = {
  BODY_BACK: '#f2f2f2',
  FONT: '#222',
};

export const DELAYTIME = {
  SUB_TITLE: 0.5, // *
  PHRASE: 0.5, // *
};

export const DURATION = {
  SUB_TITLE: 1500,
  PHRASE: 2000,
  CARD: 1000,
};
