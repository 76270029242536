import React from 'react';

import { Box, Typography } from '@mui/material';
import ScrollReveal from 'components/scrollReveal';

type Props = {
  text: string;
  delayTime?: number;
  styles: React.CSSProperties;
};

/**
 * 要素を左から表示する
 * @param props
 * @returns
 */
const characterDisplayFromLeft: React.FC<Props> = (props) => {
  return (
    <Box sx={{ borderLeft: 2, paddingLeft: '3px', marginBottom: '10px' }}>
      <ScrollReveal
        option={{
          // viewOffset: { top: 0, right: 0, bottom: 100, left: 0 },
          duration: 1000,
          origin: 'left',
          distance: '10px',
          reset: false,
          opacity: 0,
          delay: 100,
        }}
      >
        <Typography style={props.styles}>{props.text}</Typography>
      </ScrollReveal>
    </Box>
  );
};

export default characterDisplayFromLeft;
