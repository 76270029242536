export const COLORS = {
  BODY_BACK: '#f2f2f2',
  FONT: '#222',
};

export const DELAYTIME = {
  TITLE: 0.3, // *
  SUB_TITLE: 300,
  PHRASE: 500,
};

export const DURATION = {
  SUB_TITLE: 1000,
  PHRASE: 1500,
};
