import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION } from 'const/inHouseServiceConst';

/**
 * Webサービスのページ
 * @returns
 */
const InHouseService: React.FC = () => {
  const [width, height] = useWindowSize();

  /**
   * サービス内容
   * @param text サービス説明
   * @param image 画像名
   * @param borderFlag 区切りの線をつけるか
   * @param url サービスURL
   * @param urlManual サービスに関するマニュアルのURL
   * @param time 表示遅延時間
   * @returns
   */
  const serviceContent = (
    text: string,
    image: string,
    borderFlag: boolean,
    url: string,
    urlManual: string | undefined,
    time: number
  ) => {
    return (
      <Grid item xs={6} sm={4}>
        <TrimCircle
          fontSize={`${width / 53}px`}
          text={text}
          imageName={image}
          alt={image}
          borderFlag={borderFlag}
          style={{
            width: `${width / 7}px`,
            height: `${width / 7}px`,
            borderRadius: '50%',
          }}
          url={url}
          urlManual={urlManual}
          padding={'2px 0px'}
          paperStyle={{ borderRadius: '50%' }}
          option={{
            viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
            origin: 'left',
            distance: '1000px',
            rotate: { x: 0, y: 0, z: 180 },
            duration: DURATION.SERVICE,
            reset: false,
            opacity: 0,
            delay: time * DELAYTIME.SERVICE,
          }}
        ></TrimCircle>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {/* 画面の縦幅 - ヘッダー高さ - フッター高さ - ヘッダー下の余白高さ */}
      <div
        style={{
          maxWidth: '1920px',
          minHeight: `calc(${height}px - 107px - 75px - 20px)`,
          padding: '0px 24px',
          margin: 'auto',
        }}
      >
        <ThemeProvider theme={apptheme}>
          <div style={{ marginTop: '128px', textAlign: 'center' }}>
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.TITLE,
                reset: false,
                opacity: 0,
                delay: 0,
              }}
            >
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 13px)`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  marginBottom: `${width / 42}px`,
                }}
              >
                社内システム
              </Typography>
            </ScrollReveal>

            <Grid container item xs>
              {serviceContent(
                '勤怠管理',
                'inHouseService_01',
                true,
                'https://airspo.co.jp/services/common/signin.html?systemname=kinkan',
                'https://airspo.co.jp/services/manual/%E5%8B%A4%E6%80%A0%E7%AE%A1%E7%90%86%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf',
                500
              )}
              {serviceContent(
                '勤怠管理Windowsアプリ版',
                'inHouseService_02',
                true,
                'https://airspo.co.jp/download/KinForm.zip',
                undefined,
                300
              )}
              {serviceContent(
                'AIRSPOT WIKI',
                'inHouseService_03',
                false,
                'https://airspo.co.jp/wiki',
                undefined,
                100
              )}
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default InHouseService;
