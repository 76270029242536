import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION } from 'const/webServiceConst';

/**
 * Webサービスのページ
 * @returns
 */
const WebService: React.FC = () => {
  const [width, height] = useWindowSize();

  /**
   * サービス内容
   * @param text サービス説明
   * @param image 画像名
   * @param borderFlag 区切りの線をつけるか
   * @param url サービスURL
   * @param urlManual サービスに関するマニュアルのURL
   * @param time 表示遅延時間
   * @param size 文字サイズ
   * @returns
   */
  const serviceContent = (
    text: string,
    image: string,
    borderFlag: boolean,
    url: string,
    urlManual: string | undefined,
    time: number,
    size?: string
  ) => {
    return (
      <Grid item xs={6} sm={4}>
        <TrimCircle
          fontSize={size !== undefined ? size : `${width / 53}px`}
          text={text}
          imageName={image}
          alt={image}
          borderFlag={borderFlag}
          style={{
            width: `${width / 7}px`,
            height: `${width / 7}px`,
            borderRadius: '50%',
          }}
          url={url}
          urlManual={urlManual}
          padding={'2px 0px'}
          paperStyle={{ borderRadius: '50%' }}
          option={{
            viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
            origin: 'left',
            distance: '1000px',
            rotate: { x: 0, y: 0, z: 180 },
            duration: DURATION.SERVICE,
            reset: false,
            opacity: 0,
            delay: time * DELAYTIME.SERVICE,
          }}
        ></TrimCircle>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {/* 画面の縦幅 - ヘッダー高さ - フッター高さ - ヘッダー下の余白高さ */}
      <div
        style={{
          maxWidth: '1920px',
          minHeight: `calc(${height}px - 107px - 75px - 20px)`,
          padding: '0px 24px',
          margin: 'auto',
        }}
      >
        <ThemeProvider theme={apptheme}>
          <div style={{ marginTop: '128px', textAlign: 'center' }}>
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.TITLE,
                reset: false,
                opacity: 0,
                delay: 0,
              }}
            >
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 13px)`,
                  // fontSize: `${width / 64}px`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  marginBottom: `${width / 42}px`,
                }}
              >
                公開サービス
              </Typography>
            </ScrollReveal>

            <Grid container item xs>
              {serviceContent(
                '法人情報検索サービス',
                'webService_01',
                true,
                'https://airspo.co.jp/services/hojin/index.html',
                undefined,
                500
              )}
              {serviceContent(
                '郵便番号検索サービス',
                'webService_02',
                true,
                'https://airspo.co.jp/services/zip/index.html',
                undefined,
                300
              )}
              {serviceContent(
                '日本の祝日サービス',
                'webService_03',
                false,
                'https://airspo.co.jp/services/holiday/index.html',
                undefined,
                100
              )}
            </Grid>
          </div>

          <div style={{ marginTop: '128px', textAlign: 'center' }}>
            <Grid container item xs>
              {serviceContent(
                '弥生会計【入力支援ツール】（個人事業主向）',
                'webService_05',
                true,
                'https://airspo.co.jp/download/YayoiTool.zip',
                undefined,
                300,
                `${width / 71}px`
              )}
              {serviceContent(
                'eL-TAX向け市民税CSV作成支援ツール',
                'webService_06',
                true,
                'https://airspo.co.jp/download/elTAX_ShiZeiTool.xlsm',
                undefined,
                100,
                `${width / 64}px`
              )}
              {serviceContent(
                '製品寿命管理アプリ',
                'webService_07',
                false,
                'https://play.google.com/store/apps/details?id=com.airspo.productmanagementapp&hl=ja-JP&pli=1',
                undefined,
                100,
                `${width / 64}px`
              )}
            </Grid>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default WebService;