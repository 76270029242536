export const COLORS = {
  TITLE_BACK: 'rgba(0,0,0,0.7)',
  BODY_BACK: '#f2f2f2',
  CARD_BACK: '#e8e8e8',
  TITLE_FONT: '#f3fbff',
  FONT: '#222',
  CURSOR: '#3F3D56',
};

export const DELAYTIME = {
  SUB_TITLE: 0.5, // *
  PHRASE: 0.5, // *
  CARD: 200,
  NEWS: 200,
  EVENT: 200,
  BUTTON: 400,
  SERVICE: 1, // *
};

export const DURATION = {
  SUB_TITLE: 1500,
  PHRASE: 2000,
  CARD: 1000,
  NEWS: 1500,
  EVENT: 1500,
  BUTTON: 1000,
  SERVICE: 1500,
};

export const TYPEWRITER = {
  DELAY1: 1500,
  DELAY2: 3000,
  SPEED: 100,
};
