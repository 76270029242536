import React from 'react';

import { Typography } from '@mui/material';
import ScrollReveal from 'components/scrollReveal';

type Props = {
  text: string;
  delayTime: number;
  styles: React.CSSProperties;
};

/**
 * 要素を1文字ずつ表示する
 * @param props
 * @returns
 */
const OneCharacterByDisplay: React.FC<Props> = (props) => {
  return (
    <ScrollReveal
      option={{
        reset: false,
        distance: '-100px',
        scale: 1.8,
        rotate: {
          x: 0,
          y: 300,
          z: 0,
        },
        delay: props.delayTime,
        viewOffset: { top: 0, right: -100, bottom: -300, left: 0 },
      }}
    >
      <Typography style={props.styles}>{props.text}</Typography>
    </ScrollReveal>
  );
};

export default OneCharacterByDisplay;
