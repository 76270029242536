import React from 'react';

import { Avatar, Card, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material';
import { useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/achievementConst';

import AvatarSetting from './avatarSetting';

let obj: {
  title: string;
  text: string;
  iconImage?: string;
  developerName: string;
  comment: string;
  color: string;
  you?: string;
  ki?: string;
  sei?: string;
  tan?: string;
  ketu?: string;
};
type Props = {
  isDevelop: boolean;
  propsObj: typeof obj;
};

/**
 * 開発支援の実績カード(モバイル)
 * @param props
 * @returns
 */
const AchievementCardMobile: React.FC<Props> = (props) => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  /**
   * カードのスクロールエフェクトにつけているオプション情報
   * @returns
   */
  const cardScrollRevealOption = () => {
    return {
      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
      origin: 'left',
      distance: '1000px',
      duration: DURATION.CARD,
      reset: false,
      opacity: 0,
      delay: DELAYTIME.CARD,
    };
  };

  /**
   * モバイル版の配置処理
   * @param isDevelopSupport true:開発支援,false:自社開発
   * @param title タイトル
   * @param info 説明
   * @param comment 担当者コメント
   * @param color マークの色
   * @returns
   */
  const mobileDisplay = (
    isDevelopSupport: boolean,
    title: string,
    info: string,
    developerName: string,
    comment: string,
    color: string
  ) => {
    return (
      <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
        <ScrollReveal option={cardScrollRevealOption()}>
          <Card
            style={{
              maxWidth: '480px',
              maxHeight: 'auto',
              margin: '10px 10px',
              backgroundColor: COLORS.CARD_BACK,
            }}
            elevation={12}
          >
            <CardHeader
              titleTypographyProps={{
                fontSize: `max(${width / 91}px, 13px)`,
                textAlign: 'start',
              }}
              title={`${title.slice(1)}`}
              style={{
                backgroundColor: 'white',
              }}
              avatar={
                <Avatar
                  component={Paper}
                  elevation={3}
                  sx={{ width: 32, height: 32 }}
                  style={{
                    color: 'black',
                    backgroundColor: `${color}`,
                    marginRight: '-10px',
                  }}
                >
                  {`${title.charAt(0)}`}
                </Avatar>
              }
            />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ textAlign: 'start' }}
              >
                {info}
              </Typography>
              {isDevelopSupport && (
                <Grid container item style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Card
                    style={{
                      maxWidth: '480px',
                      maxHeight: '300px',
                      margin: '10px 10px',
                      backgroundColor: COLORS.CARD_BACK,
                    }}
                  >
                    <CardHeader
                      titleTypographyProps={{
                        fontSize: `max(${width / 91}px, 13px)`,
                        textAlign: 'start',
                      }}
                      title={developerName}
                      style={{
                        backgroundColor: 'white',
                        height: `${width / 32}px`,
                      }}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {comment}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Card elevation={0} style={{ backgroundColor: COLORS.CARD_BACK }}>
                <CardHeader
                  style={{ padding: '16px 0px 0px 0px', alignItems: 'flex-end' }}
                  avatar={
                    isDevelopSupport && (
                      <AvatarSetting
                        you={props.propsObj.you !== undefined ? props.propsObj.you : ''}
                        ki={props.propsObj.ki !== undefined ? props.propsObj.ki : ''}
                        sei={props.propsObj.sei !== undefined ? props.propsObj.sei : ''}
                        tan={props.propsObj.tan !== undefined ? props.propsObj.tan : ''}
                        ketu={props.propsObj.ketu !== undefined ? props.propsObj.ketu : ''}
                      ></AvatarSetting>
                    )
                  }
                />
              </Card>
            </CardContent>
          </Card>
        </ScrollReveal>
      </Grid>
    );
  };

  return (
    <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
      {mobileDisplay(
        props.isDevelop,
        props.propsObj.title,
        props.propsObj.text,
        props.propsObj.developerName,
        props.propsObj.comment,
        props.propsObj.color
      )}
    </Grid>
  );
};

export default AchievementCardMobile;
