import React from 'react';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import AchievementCardDevelop from 'components/achievementCardDevelop';
import AchievementCardOrigin from 'components/achievementCardOrigin';
import GameVideoCard from 'components/gameVideoCard';
import GameVideoCardMobile from 'components/gameVideoCardMobile';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DURATION } from 'const/achievementConst';

import AchievementDataDevelop from '../data/achievementDataDevelop.json';
import AchievementDataOrigin from '../data/achievementDataOrigin.json';
// import video_webm from '../movie/demoTestSmall.webm';

/**
 * 実績のページ
 * @returns
 */
const Achievement: React.FC = () => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  // const videoRef = React.useRef<HTMLVideoElement>(null);
  // React.useEffect(() => {
  //   videoRef.current?.play();
  // }, []);

  return (
    <React.Fragment>
      <div style={{ maxWidth: '1920px', padding: '0px 24px', margin: 'auto' }}>
        <ThemeProvider theme={apptheme}>
          <div
            style={{
              marginTop: '128px',
              paddingTop: '30px',
              paddingBottom: '30px',
              backgroundColor: COLORS.BODY_BACK,
            }}
          >
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                reset: false,
                opacity: 0,
                delay: 0,
              }}
            >
              <Typography
                style={{
                  fontSize: `${width / 40}px`,
                  fontWeight: 'bold',
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  textAlign: 'center',
                }}
              >
                DEVELOPMENT SUPPORT
              </Typography>
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 8px)`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                開発支援
              </Typography>
            </ScrollReveal>

            <div>
              {AchievementDataDevelop.achievementDataDevelop.length > 0 &&
                AchievementDataDevelop.achievementDataDevelop?.map((item, index) => (
                  <AchievementCardDevelop key={index} {...item} />
                ))}
            </div>
          </div>

          <div
            style={{
              marginTop: '128px',
              paddingTop: '30px',
              paddingBottom: '30px',
              backgroundColor: COLORS.BODY_BACK,
            }}
          >
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                reset: false,
                opacity: 0,
                delay: 0,
              }}
            >
              <Typography
                style={{
                  fontSize: `${width / 40}px`,
                  fontWeight: 'bold',
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  textAlign: 'center',
                }}
              >
                INTERNAL DEVELOPMENT
              </Typography>
              <Typography
                style={{
                  fontSize: `max(${width / 64}px, 8px)`,
                  color: COLORS.FONT,
                  letterSpacing: '10px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                自社開発
              </Typography>
            </ScrollReveal>

            <div>
              <Grid
                container
                item
                xs={12}
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <div>
                  {/* 現在の表示方法に合わせたゲーム動画(PCなら試遊ボタンも)を用意する */}
                  {isMobile === false ? (
                    <GameVideoCard></GameVideoCard>
                  ) : (
                    <GameVideoCardMobile></GameVideoCardMobile>
                  )}
                  {AchievementDataOrigin.achievementDataOrigin.length > 0 &&
                    AchievementDataOrigin.achievementDataOrigin?.map((item, index) => (
                      <AchievementCardOrigin
                        key={index}
                        title={item.title}
                        text={item.text}
                        developerName={''}
                        comment={item.comment}
                        color={item.color}
                        ki={''}
                        sei={''}
                        tan={''}
                        ketu={''}
                      />
                    ))}
                </div>
              </Grid>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default Achievement;
