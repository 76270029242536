import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Grid, Paper, Typography } from '@mui/material';
import { ColorChange, useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';

/**
 * コンポーネントの引数
 */
type Props = {
  text: string;
  comment?: string;
  imageName: string;
  alt: string;
  borderFlag: boolean;
  fontSize: string;
  padding: string;
  option?: scrollReveal.ScrollRevealObjectOptions;
  style?: React.CSSProperties | undefined;
  paperStyle?: React.CSSProperties | undefined;
  url?: string;
  urlManual?: string;
};

/**
 * カレンダーコンポーネント
 * @param props 受け渡しパラメータ
 */
const TrimCircle: React.FC<Props> = (props) => {
  // フック
  const [value, setValue] = React.useState<number>(0);
  const [url, setURL] = React.useState<string>('');
  const [urlManual, setURLManual] = React.useState<string>('');
  const [width] = useWindowSize();

  // 反作用フック
  useEffect(() => {
    if (props.text !== '') {
      setValue(70);
    }
  }, []);

  useEffect(() => {
    if (props.url !== undefined) {
      setURL(props.url);
    }
  }, [props.url]);

  useEffect(() => {
    if (props.urlManual !== undefined) {
      setURLManual(props.urlManual);
    }
  }, [props.urlManual]);

  const openInNewTab = (url: string) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div
        style={
          props.borderFlag
            ? { borderRight: '1px solid #000000', padding: `${props.padding}` }
            : { padding: `${props.padding}` }
        }
      >
        <ScrollReveal
          option={{
            viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
            duration: 2000,
            reset: false,
            opacity: 0,
            delay: 300,
          }}
        >
          <Typography
            style={{
              textAlign: 'center',
              // fontSize: `${props.fontSize}`,
              fontSize: `max(${props.fontSize}, 8px)`,
              height: `${value / (1280 / width)}px`,
            }}
          >
            {props.text}
          </Typography>
        </ScrollReveal>
        <Grid container item style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Grid
            container
            item
            xs={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: isMobile ? '15px 0px 20px' : '0px 0px 20px',
            }}
          >
            <ScrollReveal option={props.option !== undefined ? props.option : {}}>
              <Paper elevation={12} style={props.paperStyle}>
                <img
                  src={`${process.env.PUBLIC_URL}/image/${props.imageName}.png`}
                  alt={props.alt}
                  style={props.style}
                />
              </Paper>
            </ScrollReveal>
          </Grid>
          {props.url !== undefined && (
            <ColorChange
              variant="contained"
              style={{
                width: `${width / 5.6}px`,
                height: `${width / 18}px`,
                margin: `${width / 20}px 0px`,
              }}
              onClick={() => openInNewTab(url)}
            >
              <p
                style={{
                  fontSize: `${width / 64}px`,
                }}
              >
                USE
              </p>
            </ColorChange>
          )}
          {props.urlManual !== undefined && (
            <Button
              variant="contained"
              style={{
                width: `${width / 14}px`,
                height: `${width / 28}px`,
                margin: `${width / 20}px 0px ${width / 20}px ${width / 128}px`,
              }}
              onClick={() => openInNewTab(urlManual)}
            >
              <p
                style={{
                  fontSize: `${width / 80}px`,
                }}
              >
                MANUAL
              </p>
            </Button>
          )}

          <ScrollReveal
            option={{
              // viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
              duration: 500,
              reset: false,
              opacity: 0,
              delay: 1000,
            }}
          >
            <Typography
              style={{
                textAlign: 'center',
                fontSize: `max(${width / 80}px, 8px)`,
                // fontSize: `${width / 80}px`,
                height: `${value / (1280 / width)}px`,
              }}
            >
              {props.comment}
            </Typography>
          </ScrollReveal>
        </Grid>
      </div>
    </>
  );
};

export default TrimCircle;
