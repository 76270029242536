export const COLORS = {
  FONT: '#222',
};

export const DELAYTIME = {
  SERVICE: 1, // *
};

export const DURATION = {
  TITLE: 1000,
  SERVICE: 1000,
};
