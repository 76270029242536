import React from 'react';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import CharacterDisplayFromLeft from 'components/characterDisplayFromLeft';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/productConst';

import ProductData from '../data/productData.json';

/**
 * 製品のページ
 * @returns
 */
const Product: React.FC = () => {
  const [width, height] = useWindowSize();

  return (
    <div
      style={{
        maxWidth: '1920px',
        minHeight: `calc(${height}px - 107px - 75px - 20px)`,
        padding: '0px 24px',
        margin: 'auto',
      }}
    >
      <ThemeProvider theme={apptheme}>
        {ProductData?.map(
          (
            datas: {
              title: string;
              contents: string;
              column1: string;
              column2: string;
              column3: string;
              color: string;
              list: any;
            },
            index: number
          ) => (
            <React.Fragment key={index}>
              <>
                <div style={{ marginTop: '128px' }}>
                  <Box>
                    <CharacterDisplayFromLeft
                      text={datas.title}
                      styles={{
                        fontSize: `max(${width / 64}px, 16px)`,
                        color: COLORS.FONT,
                        letterSpacing: '10px',
                        fontWeight: 'bold',
                        marginBottom: `${width / 42}px`,
                        backgroundColor: datas.color,
                      }}
                    ></CharacterDisplayFromLeft>

                    <ScrollReveal
                      option={{
                        duration: DURATION.TEXT,
                        reset: false,
                        opacity: 0,
                        delay: DELAYTIME.TEXT,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: `max(${width / 80}px, 13px)`,
                          color: COLORS.FONT,
                          lineHeight: `${width / 30}px`,
                        }}
                      >
                        {datas.contents}
                        <br></br>
                      </Typography>
                    </ScrollReveal>
                  </Box>
                </div>
                <div style={{ padding: '30px 0px', backgroundColor: COLORS.BODY_BACK }}>
                  <ScrollReveal
                    option={{
                      origin: 'left',
                      distance: '50px',
                      duration: DURATION.TABLE,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TABLE,
                    }}
                  >
                    <Card
                      style={
                        isMobile
                          ? { width: '90%', margin: 'auto' }
                          : { width: '70%', margin: 'auto' }
                      }
                      elevation={12}
                    >
                      <CardHeader />
                      <CardContent>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ fontSize: `max(${width / 80}px, 10px)` }}>
                                  {datas.column1}
                                </TableCell>
                                <TableCell style={{ fontSize: `max(${width / 80}px, 10px)` }}>
                                  {datas.column2}
                                </TableCell>
                                <TableCell style={{ fontSize: `max(${width / 80}px, 10px)` }}>
                                  {datas.column3}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {datas.list?.map(
                                (body: { column1: string; column2: string; column3: string }) => (
                                  <TableRow key={body.column1}>
                                    <TableCell
                                      style={{
                                        fontSize: `max(${width / 91}px, 8px)`,
                                        width: `${width / 4.8}px`,
                                      }}
                                    >
                                      {body.column1}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: `max(${width / 91}px, 8px)`,
                                        width: `${width / 9.6}px`,
                                      }}
                                    >
                                      {body.column2}
                                    </TableCell>
                                    <TableCell style={{ fontSize: `max(${width / 91}px, 8px)` }}>
                                      {body.column3}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </ScrollReveal>
                </div>
              </>
            </React.Fragment>
          )
        )}

        <Typography
          style={{
            fontSize: `max(${width / 80}px, 13px)`,
            padding: '5px',
          }}
        >
          {'また、電話番号や、メールアドレスを取得したい場合などは、別途ご相談ください。'}
        </Typography>

        {/* お問い合わせ */}
        <div style={{ marginTop: '128px' }}>
          <Box sx={{ borderBottom: 1, margin: '50px' }} style={{ margin: '20px' }}></Box>
          <Box>
            <CharacterDisplayFromLeft
              text={'お問い合わせ'}
              styles={{
                fontSize: `max(${width / 64}px, 16px)`,
                color: COLORS.FONT,
                letterSpacing: '10px',
                fontWeight: 'bold',
                marginBottom: `${width / 42}px`,
              }}
            ></CharacterDisplayFromLeft>

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: 100, left: 0 },
                duration: DURATION.TEXT,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.TEXT,
              }}
            >
              <Typography
                style={{
                  fontSize: `max(${width / 80}px, 13px)`,
                  padding: '5px',
                  color: COLORS.FONT,
                  marginBottom: `${width / 20}px`,
                }}
              >
                <a href="mailto:sales@airspo.co.jp"> sales@airspo.co.jp</a>
                まで、ご連絡ください。
              </Typography>
            </ScrollReveal>
          </Box>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default Product;
