import { SessionKey } from 'constants/sessionKey';

import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

// 言語jsonファイルのimport
import translation_en from './en.json';
import translation_ja from './ja.json';

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: sessionStorage.getItem(SessionKey.language) ?? 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnObjects: true,
  });

export default i18n;
