import React from 'react';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';
import { Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import OneCharacterByDisplay from 'components/oneCharacterByDisplay';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION } from 'const/serviceConst';
import { t } from 'i18next';

/**
 * 事業内容のページ
 * @returns
 */
const Service: React.FC = () => {
  const [width] = useWindowSize();

  /**
   * サブタイトルの文字スタイル
   * @returns
   */
  const subTitleTypo = () => {
    return {
      fontSize: `max(${width / 64}px, 13px)`,
      color: COLORS.FONT,
      letterSpacing: '5px',
      fontWeight: 'bold',
      marginBottom: `${width / 42}px`,
    };
  };

  /**
   * 説明文の文字スタイル
   * @returns
   */
  const infoTypo = () => {
    return {
      fontSize: `max(${width / 80}px, 13px)`,
      color: COLORS.FONT,
      lineHeight: `${width / 25}px`,
    };
  };

  /**
   * 表示テキストのfor文処理
   * @param text テキスト
   * @param property スタイル設定
   * @returns
   */
  const messageText = (text: string, property: React.CSSProperties = infoTypo()) => {
    const items = [];
    for (let i = 0; i < t(text).length; i++) {
      items.push(
        <li key={i.toString()}>
          <Typography style={property}>{t(text)[i]}</Typography>
        </li>
      );
    }
    return <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>{items}</ul>;
  };

  /**
   * テキストを1文字ずつ表示する処理
   * @param text テキスト
   * @returns
   */
  const oneChacterByDisplay = (text: string) => {
    return (
      <div style={{ display: 'inline-flex' }}>
        {t(text)
          .split('')
          .map((item, index) => (
            <OneCharacterByDisplay
              key={index}
              text={item}
              delayTime={index * 100 * DELAYTIME.TITLE}
              styles={{
                fontSize: `max(${width / 40}px, 16px)`,
                fontWeight: 'bold',
                color: COLORS.FONT,
                letterSpacing: '10px',
                marginBottom: `${width / 25}px`,
              }}
            ></OneCharacterByDisplay>
          ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={apptheme}>
        <div
          style={{ maxWidth: '1920px', padding: '0px 24px', margin: 'auto', textAlign: 'center' }}
        >
          <div
            style={
              width >= 700
                ? {
                    textAlign: 'center',
                    marginTop: `128px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
                : {
                    textAlign: 'start',
                    marginTop: `128px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
            }
          >
            {oneChacterByDisplay(t('service.ソフトウェア開発支援'))}

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                origin: 'left',
                distance: '1000px',
                reset: false,
                opacity: 0,
                delay: DELAYTIME.SUB_TITLE,
              }}
            >
              {messageText('service.ソフトウェア開発支援見出し', subTitleTypo())}
            </ScrollReveal>

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.PHRASE,
                scale: 0.1,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.PHRASE,
              }}
            >
              {messageText('service.ソフトウェア開発支援説明文')}

              <div>
                <TrimCircle
                  fontSize={`${width / 53}px`}
                  text={''}
                  imageName={'service_01'}
                  alt={'service_01'}
                  borderFlag={false}
                  style={{
                    width: `${width / 1.6}px`,
                    height: `${width / 3.2}px`,
                    borderRadius: '5%',
                  }}
                  padding={isMobile ? '30px 70px 0px' : '2px 70px'}
                  paperStyle={{ borderRadius: '5%' }}
                ></TrimCircle>
              </div>
            </ScrollReveal>
          </div>

          <div
            style={
              width >= 700
                ? {
                    textAlign: 'center',
                    marginTop: `128px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
                : {
                    textAlign: 'start',
                    marginTop: `${width / 8.5}px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
            }
          >
            {oneChacterByDisplay(t('service.ソフトウェアによる業務改善'))}

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                origin: 'left',
                distance: '300px',
                reset: false,
                opacity: 0,
                delay: DELAYTIME.SUB_TITLE,
              }}
            >
              {messageText('service.ソフトウェアによる業務改善見出し', subTitleTypo())}
            </ScrollReveal>

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.PHRASE,
                scale: 0.1,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.PHRASE,
              }}
            >
              {messageText('service.ソフトウェアによる業務改善説明文')}

              <div>
                <TrimCircle
                  fontSize={`${width / 53}px`}
                  text={''}
                  imageName={'service_02'}
                  alt={'service_02'}
                  borderFlag={false}
                  style={{
                    width: `${width / 1.6}px`,
                    height: `${width / 3.2}px`,
                    borderRadius: '5%',
                  }}
                  padding={isMobile ? '30px 70px 0px' : '2px 70px'}
                  paperStyle={{ borderRadius: '5%' }}
                ></TrimCircle>
              </div>
            </ScrollReveal>
          </div>

          <div
            style={
              width >= 700
                ? {
                    textAlign: 'center',
                    marginTop: `128px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
                : {
                    textAlign: 'start',
                    marginTop: `${width / 8.5}px`,
                    padding: '30px 15px',
                    backgroundColor: COLORS.BODY_BACK,
                  }
            }
          >
            {oneChacterByDisplay(t('service.ＶＰＮサーバーの構築'))}

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.SUB_TITLE,
                origin: 'left',
                distance: '300px',
                reset: false,
                opacity: 0,
                delay: DELAYTIME.SUB_TITLE,
              }}
            >
              {messageText('service.ＶＰＮサーバーの構築見出し', subTitleTypo())}
            </ScrollReveal>

            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.PHRASE,
                scale: 0.1,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.PHRASE,
              }}
            >
              {messageText('service.ＶＰＮサーバーの構築説明文')}

              <div>
                <TrimCircle
                  fontSize={`${width / 53}px`}
                  text={''}
                  imageName={'service_03'}
                  alt={'service_03'}
                  borderFlag={false}
                  style={{
                    width: `${width / 1.6}px`,
                    height: `${width / 3.2}px`,
                    borderRadius: '5%',
                  }}
                  padding={isMobile ? '30px 70px 0px' : '2px 70px'}
                  paperStyle={{ borderRadius: '5%' }}
                ></TrimCircle>
              </div>
            </ScrollReveal>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Service;
