import React, { FC, useEffect, useRef } from 'react';

import scrollReveal from 'scrollreveal';

interface ScrollRevealContainerProps {
  children: any;
  option: scrollReveal.ScrollRevealObjectOptions;
}

const ScrollReveal: FC<ScrollRevealContainerProps> = ({ children, option }) => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (sectionRef.current) scrollReveal().reveal(sectionRef.current, option);
  }, [sectionRef]);

  return <section ref={sectionRef}>{children}</section>;
};
export default ScrollReveal;
