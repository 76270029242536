import React from 'react';
import { isMobile } from 'react-device-detect';

import { Avatar, Card, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material';
import { useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/achievementConst';

import AchievementCardMobile from './achievementCardMobile';
import AvatarSetting from './avatarSetting';

type Props = {
  title: string;
  text: string;
  iconImage: string;
  developerName: string;
  comment: string;
  color: string;
  you: string;
  ki: string;
  sei: string;
  tan: string;
  ketu: string;
};

/**
 * 開発支援の実績カード
 * @param props
 * @returns
 */
const AchievementCardDevelop: React.FC<Props> = (props) => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  /**
   * カードのスクロールエフェクトにつけているオプション情報
   * @returns
   */
  const cardScrollRevealOption = () => {
    return {
      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
      origin: 'left',
      distance: '1000px',
      duration: DURATION.CARD,
      reset: false,
      opacity: 0,
      delay: DELAYTIME.CARD,
    };
  };

  /**
   * 開発支援カード
   * @param title タイトル
   * @param info 説明
   * @param comment 担当者コメント
   * @param color マークの色
   * @returns
   */
  const develop = (
    title: string,
    info: string,
    comment: string,
    color: string,
    you: string,
    ki: string,
    sei: string,
    tan: string,
    ketu: string
  ) => {
    // モバイル版ならば、引数だけ渡して別のメソッドに入る
    if (isMobile) {
      return <AchievementCardMobile isDevelop={true} propsObj={props}></AchievementCardMobile>;
    }

    return (
      <Grid
        container
        item
        xs={12}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: COLORS.BODY_BACK,
        }}
      >
        <ScrollReveal option={cardScrollRevealOption()}>
          <Card
            style={{
              width: `${width / 1.6}px`,
              maxWidth: '800px',
              maxHeight: 'auto',
              margin: '10px 10px',
              backgroundColor: COLORS.CARD_BACK,
            }}
            elevation={12}
          >
            <CardHeader
              titleTypographyProps={{ fontSize: `${width / 91}px` }}
              title={`${title.slice(1)}`}
              style={{
                backgroundColor: 'white',
              }}
              avatar={
                <Avatar
                  component={Paper}
                  elevation={3}
                  sx={{ width: 32, height: 32 }}
                  style={{
                    color: 'black',
                    backgroundColor: `${color}`,
                    fontSize: `${width / 91}px`,
                    marginRight: `max(-${width / 80}px, -10px)`,
                  }}
                >
                  {`${title.charAt(0)}`}
                </Avatar>
              }
            />
            <CardContent>
              <Grid
                container
                item
                xs={12}
                style={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}
              >
                <Grid
                  container
                  item
                  xs={6}
                  style={{ alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Typography variant="body2" color="textSecondary" component="p">
                    {info}
                  </Typography>

                  <Card elevation={0} style={{ backgroundColor: COLORS.CARD_BACK }}>
                    <CardHeader
                      style={{ padding: '16px 0px 0px 0px', alignItems: 'flex-start' }}
                      avatar={
                        <AvatarSetting
                          you={props.you}
                          ki={props.ki}
                          sei={props.sei}
                          tan={props.tan}
                          ketu={props.ketu}
                        ></AvatarSetting>
                      }
                    />
                  </Card>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Card
                    style={{
                      width: '480px',
                      maxHeight: 'auto',
                      margin: '10px 10px',
                      backgroundColor: COLORS.CARD_BACK,
                    }}
                  >
                    <CardHeader
                      titleTypographyProps={{
                        fontSize: `max(${width / 91}px, 13px)`,
                      }}
                      title={props.developerName}
                      style={{
                        backgroundColor: 'white',
                        height: `${width / 71}px`,
                      }}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {comment}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </ScrollReveal>
      </Grid>
    );
  };

  return (
    <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
      {develop(
        props.title,
        props.text,
        props.comment,
        props.color,
        props.you,
        props.ki,
        props.sei,
        props.tan,
        props.ketu
      )}
    </Grid>
  );
};

export default AchievementCardDevelop;
