import React from 'react';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';
import ForwardIcon from '@mui/icons-material/Forward';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import CharacterDisplayFromLeft from 'components/characterDisplayFromLeft';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION } from 'const/recruitInfoConst';
import { t } from 'i18next';

/**
 * 採用情報のページ
 * @returns
 */
const RecruitInfo: React.FC = () => {
  const [width] = useWindowSize();

  /**
   * 説明文の文字スタイル
   * @returns
   */
  const infoTypo = () => {
    return {
      fontSize: `max(${width / 80}px, 11px)`,
      padding: '5px',
    };
  };

  /**
   * 見出し部分の太字文字スタイル
   * @returns
   */
  const infoBoldTypo = () => {
    return {
      fontSize: `max(${width / 64}px, 13px)`,
      color: COLORS.FONT,
      letterSpacing: '10px',
      fontWeight: 'bold',
      marginBottom: `${width / 42}px`,
    };
  };

  /**
   * 表示テキストのfor文処理
   * @param text テキスト
   * @param property スタイル設定
   * @returns
   */
  const messageText = (text: string, property: React.CSSProperties = infoTypo()) => {
    const items = [];
    for (let i = 0; i < t(text).length; i++) {
      items.push(
        <li key={i.toString()}>
          <Typography style={property}>{t(text)[i]}</Typography>
        </li>
      );
    }
    return <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>{items}</ul>;
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: '1920px', padding: '0px 24px', margin: 'auto' }}>
        <ThemeProvider theme={apptheme}>
          <ScrollReveal
            option={{
              viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
              duration: DURATION.FLOW,
              reset: false,
              opacity: 0,
              delay: 0,
            }}
          >
            <Box sx={{ borderBottom: 1 }} style={{ marginTop: '128px' }}>
              <Grid container item xs style={{ justifyContent: 'center' }}>
                <Grid container item xs style={{ justifyContent: 'center' }}>
                  {messageText('recruitInfo.選考フロー', infoBoldTypo())}
                </Grid>
              </Grid>
            </Box>
          </ScrollReveal>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: `${width / 43}px ${width / 12.8}px`,
            }}
          >
            <Grid container item xs>
              <Grid item xs={2.25} sm={2.25} style={{ alignItems: 'center' }}>
                <TrimCircle
                  fontSize={`${width / 80}px`}
                  text={'エントリー'}
                  imageName={'recruitInfo_01'}
                  alt={'recruitInfo_01'}
                  borderFlag={false}
                  style={{
                    width: `${width / 7}px`,
                    height: `${width / 7}px`,
                    borderRadius: '50%',
                  }}
                  padding={'2px 0px'}
                  paperStyle={{ borderRadius: '50%' }}
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    origin: 'left',
                    distance: '1000px',
                    rotate: { x: 0, y: 0, z: 180 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 100 * DELAYTIME.FLOW,
                  }}
                ></TrimCircle>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                style={{
                  margin: 'auto 0px',
                  paddingTop: isMobile ? '30px' : `${width / 32}px`,
                  paddingLeft: `${width / 128}px`,
                }}
              >
                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 300 * DELAYTIME.FLOW,
                  }}
                >
                  <ForwardIcon style={{ fontSize: `${width / 20}px`, color: '#b4b4b4' }} />
                </ScrollReveal>
              </Grid>

              <Grid item xs={2.25} sm={2.25} style={{ alignItems: 'center' }}>
                <TrimCircle
                  fontSize={`${width / 80}px`}
                  text={'説明会 + １次面接'}
                  imageName={'recruitInfo_02'}
                  alt={'recruitInfo_02'}
                  borderFlag={false}
                  style={{
                    width: `${width / 7}px`,
                    height: `${width / 7}px`,
                    borderRadius: '50%',
                  }}
                  padding={'2px 0px'}
                  paperStyle={{ borderRadius: '50%' }}
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    origin: 'left',
                    distance: '1000px',
                    rotate: { x: 0, y: 0, z: 180 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 300 * DELAYTIME.FLOW,
                  }}
                ></TrimCircle>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                style={{
                  margin: 'auto 0px',
                  paddingTop: isMobile ? '30px' : `${width / 32}px`,
                  paddingLeft: `${width / 128}px`,
                }}
              >
                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 500 * DELAYTIME.FLOW,
                  }}
                >
                  <ForwardIcon style={{ fontSize: `${width / 20}px`, color: '#7f7f7f' }} />
                </ScrollReveal>
              </Grid>
              <Grid item xs={2.25} sm={2.25} style={{ alignItems: 'center' }}>
                <TrimCircle
                  fontSize={`${width / 80}px`}
                  text={'２次面接(+プログラム試験)'}
                  imageName={'recruitInfo_03'}
                  alt={'recruitInfo_03'}
                  borderFlag={false}
                  style={{
                    width: `${width / 7}px`,
                    height: `${width / 7}px`,
                    borderRadius: '50%',
                  }}
                  padding={'2px 0px'}
                  paperStyle={{ borderRadius: '50%' }}
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    origin: 'left',
                    distance: '1000px',
                    rotate: { x: 0, y: 0, z: 180 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 500 * DELAYTIME.FLOW,
                  }}
                ></TrimCircle>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                style={{
                  margin: 'auto 0px',
                  paddingTop: isMobile ? '30px' : `${width / 32}px`,
                  paddingLeft: `${width / 128}px`,
                }}
              >
                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 700 * DELAYTIME.FLOW,
                  }}
                >
                  <ForwardIcon style={{ fontSize: `${width / 20}px`, color: '#5f5f5f' }} />
                </ScrollReveal>
              </Grid>
              <Grid item xs={2.25} sm={2.25} style={{ alignItems: 'center' }}>
                <TrimCircle
                  fontSize={`${width / 80}px`}
                  text={'内定'}
                  imageName={'recruitInfo_04'}
                  alt={'recruitInfo_04'}
                  borderFlag={false}
                  style={{
                    width: `${width / 7}px`,
                    height: `${width / 7}px`,
                    borderRadius: '50%',
                  }}
                  padding={'2px 0px'}
                  paperStyle={{ borderRadius: '50%' }}
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    origin: 'left',
                    distance: '1000px',
                    rotate: { x: 0, y: 0, z: 180 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: 700 * DELAYTIME.FLOW,
                  }}
                ></TrimCircle>
              </Grid>
            </Grid>
          </Box>

          <Grid container item xs={12}>
            <ScrollReveal
              option={{
                viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                duration: DURATION.TEXT,
                origin: 'left',
                distance: '300px',
                reset: false,
                opacity: 0,
                delay: DELAYTIME.TEXT,
              }}
            >
              <Box
                style={{
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  padding: `${width / 42}px ${width / 12.8}px`,
                }}
              >
                {messageText('recruitInfo.選考情報備考')}
              </Box>
            </ScrollReveal>
          </Grid>

          <ScrollReveal
            option={{
              viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
              duration: DURATION.FLOW,
              reset: false,
              opacity: 0,
              delay: 0,
            }}
          >
            <Box sx={{ borderBottom: 1 }} style={{ paddingTop: '30px' }}>
              <Grid container item xs style={{ justifyContent: 'center' }}>
                {messageText('recruitInfo.募集要項見出し', infoBoldTypo())}
              </Grid>
            </Box>
          </ScrollReveal>

          <Grid container item xs style={{ justifyContent: 'center', backgroundColor: '#f2f2f2' }}>
            <Box>
              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.給与見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.給与情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.待遇福利厚生見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.待遇福利厚生情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.仕事内容見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.仕事内容情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.求める人材見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.求める人材情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.勤務時間休暇見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.勤務時間休暇情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.採用実績校見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.採用実績校情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.その他見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  {messageText('recruitInfo.その他情報')}
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.職務経歴書見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: `max(${width / 80}px, 13px)`,
                      padding: '5px',
                    }}
                  >
                    <a
                      href="https://airspo.co.jp/download/job_template.docx"
                      download={'職務経歴書テンプレート.docx'}
                    >
                      {messageText('recruitInfo.職務経歴書')}
                    </a>
                  </Typography>
                </ScrollReveal>
              </div>

              <div style={{ padding: '30px 15px' }}>
                <CharacterDisplayFromLeft
                  text={t('recruitInfo.お問い合わせ見出し')}
                  styles={{
                    fontSize: `max(${width / 64}px, 16px)`,
                  }}
                ></CharacterDisplayFromLeft>

                <ScrollReveal
                  option={{
                    viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                    duration: DURATION.FLOW,
                    reset: false,
                    opacity: 0,
                    delay: DELAYTIME.TEXT,
                  }}
                >
                  <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>
                    <Typography style={infoTypo()}>
                      <a href="mailto:recruit@airspo.co.jp"> recruit@airspo.co.jp</a>
                    </Typography>
                    <Paper elevation={12} style={{ display: 'inline-flex' }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/image/recruitInfo_05.png`}
                        alt={'recruitInfo_05'}
                      />
                    </Paper>
                  </ul>
                </ScrollReveal>
              </div>
            </Box>
          </Grid>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default RecruitInfo;
