export const COLORS = {
  BODY_BACK: '#f2f2f2',
  CARD_BACK: '#e8e8e8',
  FONT: '#222',
};

export const DELAYTIME = {
  CARD: 200,
};

export const DURATION = {
  SUB_TITLE: 1500,
  CARD: 2000,
};
