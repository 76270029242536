import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, createTheme, styled } from '@mui/material';

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const apptheme = createTheme({
  typography: {
    fontFamily: ['Roboto', '"Noto Sans JP"', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
  },
});

export const appthemeEn = createTheme({
  typography: {
    fontFamily: [
      'Merriweather Sans',
      'Roboto',
      '"Noto Sans JP"',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

/** USEボタンにカーソルを当てているかどうかで色を反転させる */
export const ColorChange = styled(Button)({
  backgroundColor: '#e86379',
  color: '#63e8d2',
  '&:hover': {
    background: '#63e8d2',
    color: '#e86379',
  },
});

/** MOREボタンをリンクで作成してボタン風にし、カーソルを当てているかどうかで色を反転させる */
export const ColorChangeLink = styled(Link)({
  color: '#63e8d2',
  '&:hover': {
    color: '#e86379',
    background: '#63e8d2',
  },
  display: 'inline-block',
  width: '20%',
  textDecoration: 'none',
  border: '1px',
  background: '#e86379',
  boxShadow: '0px 2px 4px #575',
  textAlign: 'center',
});

export const scrollTop = (): number => {
  return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
};
