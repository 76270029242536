import React from 'react';
import { isMobile } from 'react-device-detect';

import { Avatar, Card, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material';
import { useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/achievementConst';

import AchievementCardMobile from './achievementCardMobile';

type Props = {
  title: string;
  text: string;
  developerName: string;
  comment: string;
  color: string;
  ki: string;
  sei: string;
  tan: string;
  ketu: string;
};

/**
 * 開発支援の実績カード
 * @param props
 * @returns
 */
const AchievementCardOrigin: React.FC<Props> = (props) => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  /**
   * カードのスクロールエフェクトにつけているオプション情報
   * @returns
   */
  const cardScrollRevealOption = () => {
    return {
      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
      origin: 'left',
      distance: '1000px',
      duration: DURATION.CARD,
      reset: false,
      opacity: 0,
      delay: DELAYTIME.CARD,
    };
  };

  /**
   * 自社開発カード
   * @param title タイトル
   * @param info 説明
   * @param color マークの色
   * @returns
   */
  const origin = (title: string, info: string, color: string) => {
    // モバイル版ならば、引数だけ渡して別のメソッドに入る
    if (isMobile) {
      return <AchievementCardMobile isDevelop={false} propsObj={props}></AchievementCardMobile>;
    }

    return (
      <Grid
        container
        item
        xs={12}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: COLORS.BODY_BACK,
        }}
      >
        <ScrollReveal option={cardScrollRevealOption()}>
          <Card
            style={{
              width: `${width / 1.6}px`,
              maxWidth: '800px',
              maxHeight: 'auto',
              margin: '10px 10px',
              backgroundColor: COLORS.CARD_BACK,
            }}
            elevation={12}
          >
            <CardHeader
              titleTypographyProps={{ fontSize: `${width / 91}px` }}
              title={`${title.slice(1)}`}
              style={{
                backgroundColor: 'white',
              }}
              avatar={
                <Avatar
                  component={Paper}
                  elevation={3}
                  sx={{ width: 32, height: 32 }}
                  style={{
                    backgroundColor: `${color}`,
                    color: 'black',
                    fontSize: `${width / 91}px`,
                    marginRight: `max(-${width / 80}px, -10px)`,
                  }}
                >
                  {`${title.charAt(0)}`}
                </Avatar>
              }
            />
            <CardContent>
              <Grid
                container
                item
                xs={12}
                style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}
              >
                <Typography variant="body2" color="textSecondary" component="p">
                  {info}
                </Typography>

                <Card elevation={0} style={{ backgroundColor: COLORS.CARD_BACK }}>
                  <CardHeader style={{ padding: '16px 0px 0px 0px', alignItems: 'flex-start' }} />
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </ScrollReveal>
      </Grid>
    );
  };

  return (
    <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
      {origin(props.title, props.text, props.color)}
    </Grid>
  );
};

export default AchievementCardOrigin;
