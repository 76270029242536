import React from 'react';

import { Grid, Typography } from '@mui/material';

interface FooterProps {
  fixedToBottom: boolean;
}

const Footer: React.FC<FooterProps> = ({ fixedToBottom }) => {
  return (
    <div style={{ display: 'inline', flexDirection: 'column', minHeight: '100px' }}>
      <div
        style={{
          height: '75px',
          backgroundColor: '#222',
        }}
      >
        <Grid container item xs style={{ justifyContent: 'center' }}>
          <Typography style={{ color: 'white', paddingTop: '30px', fontSize: '14px' }}>
            {'© 2019 株式会社エアスポット　AIRSPOT Inc.'}
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
