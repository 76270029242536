import React from 'react';

import { Avatar, Grid, Paper } from '@mui/material';
import { useWindowSize } from 'common/utils';
import { COLORS } from 'const/achievementConst';

type Props = {
  you: string;
  ki: string;
  sei: string;
  tan: string;
  ketu: string;
};

/**
 * 開発支援の実績カード
 * @param props
 * @returns
 */
const AvatarSetting: React.FC<Props> = (props) => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  /**
   * 基、製、単、結マークの設定
   * 特定のマークを削除したい場合は、該当マークの引数に'none'を入れる
   * @param you 要
   * @param ki 基
   * @param sei 製
   * @param tan 単
   * @param ketu 結
   * @returns
   */
  const avatarSetting = (you:string, ki: string, sei: string, tan: string, ketu: string) => {
    return (
      <>
              <Avatar
          component={Paper}
          elevation={3}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: '#D2A3CB',
            fontSize: `max(${width / 91}px, 13px)`,
            marginRight: '8px',
            marginBottom: '8px',
            color: COLORS.FONT,
            display: you,
          }}
        >
          要
        </Avatar>
        <Avatar
          component={Paper}
          elevation={3}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: '#ffb6b9',
            fontSize: `max(${width / 91}px, 13px)`,
            marginRight: '8px',
            marginBottom: '8px',
            color: COLORS.FONT,
            display: ki,
          }}
        >
          基
        </Avatar>
        <Avatar
          component={Paper}
          elevation={3}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: '#fae3d9',
            fontSize: `max(${width / 91}px, 13px)`,
            marginRight: '8px',
            marginBottom: '8px',
            color: COLORS.FONT,
            display: sei,
          }}
        >
          製
        </Avatar>
        <Avatar
          component={Paper}
          elevation={3}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: '#bbded6',
            fontSize: `max(${width / 91}px, 13px)`,
            marginRight: '8px',
            marginBottom: '8px',
            color: COLORS.FONT,
            display: tan,
          }}
        >
          単
        </Avatar>
        <Avatar
          component={Paper}
          elevation={3}
          sx={{ width: 32, height: 32 }}
          style={{
            backgroundColor: '#8ac6d1',
            fontSize: `max(${width / 91}px, 13px)`,
            marginRight: '8px',
            marginBottom: '8px',
            color: COLORS.FONT,
            display: ketu,
          }}
        >
          結
        </Avatar>
      </>
    );
  };

  return (
    <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
      {avatarSetting(props.you,props.ki, props.sei, props.tan, props.ketu)}
    </Grid>
  );
};

export default AvatarSetting;
