import React from 'react';
import { isMobile } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';
import { Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import OneCharacterByDisplay from 'components/oneCharacterByDisplay';
import ScrollReveal from 'components/scrollReveal';
import TrimCircle from 'components/trimCircle';
import { COLORS, DELAYTIME, DURATION } from 'const/aboutUsConst';
import { t } from 'i18next';

/**
 * 「私たち」のページ
 * @returns
 */
const AboutUs: React.FC = () => {
  // 画面サイズ取得
  const [width] = useWindowSize();

  /**
   * 見出し文字のスタイル
   * 使用箇所：MISSION,VALUE,SLOGAN,SPILIT
   * @returns
   */
  const headLine = () => {
    return {
      fontSize: `max(${width / 64}px, 10px)`,
      color: COLORS.FONT,
      letterSpacing: '10px',
      fontWeight: 'bold',
      marginBottom: `${width / 42}px`,
    };
  };

  /**
   * 「取り組み」と「今後の事業展開」の文字スタイル
   * @returns
   */
  const attemptAndFutureBusiness = () => {
    return {
      fontSize: `max(${width / 41}px, 13px)`,
      color: COLORS.FONT,
      letterSpacing: '10px',
      fontWeight: 'bold',
      marginBottom: `${width / 42}px`,
    };
  };

  /**
   * 「取り組み」と「今後の事業展開」に記載している説明文の文字スタイル
   * @returns
   */
  const messageTypo = () => {
    return {
      fontSize: `max(${width / 80}px, 8px)`,
      color: COLORS.FONT,
      lineHeight: `${width / 25}px`,
    };
  };

  /**
   * 「取り組み」と「今後の事業展開」に記載しているタイトル
   * @param title
   * @returns
   */
  const messageTitle = (title: string) => {
    return (
      <ScrollReveal
        option={{
          viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
          rotate: { x: 180, y: 0, z: 0 },
          duration: DURATION.SUB_TITLE,
          origin: 'left',
          distance: '500px',
          reset: false,
          opacity: 0,
          delay: 100 * DELAYTIME.SUB_TITLE,
        }}
      >
        <Typography
          style={{
            fontSize: `max(${width / 40}px, 20px)`,
            fontWeight: 'bold',
            color: COLORS.FONT,
            letterSpacing: '10px',
            marginBottom: `${width / 25}px`,
          }}
        >
          {title}
        </Typography>
      </ScrollReveal>
    );
  };

  /**
   * 画像設定
   * @param imageName 画像名
   * @param opacityNum 透過度の値
   * @returns
   */
  const imageSetting = (imageName: string, opacityNum: string) => {
    return (
      <TrimCircle
        fontSize={`${width / 53}px`}
        text={''}
        imageName={imageName}
        alt={imageName}
        borderFlag={false}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '300px',
          borderRadius: '0%',
          opacity: opacityNum,
        }}
        padding={'2px 0px'}
      ></TrimCircle>
    );
  };

  /**
   * 1文字ずつ表示する
   * @param text テキスト
   * @param style 文字スタイル
   * @returns
   */
  const oneChacterByDisplay = (text: string, style: React.CSSProperties = headLine()) => {
    return (
      <div style={{ display: 'inline-flex' }}>
        {t(text)
          .split('')
          .map((item, index) => (
            <OneCharacterByDisplay
              key={index}
              text={item}
              delayTime={index * 100 * DELAYTIME.SUB_TITLE}
              styles={style}
            ></OneCharacterByDisplay>
          ))}
      </div>
    );
  };

  /**
   * 企業理念
   * @param text テキスト
   * @param marginBottom 下の余白部分
   * @returns
   */
  const corporatePhilosophy = (text: string, marginBottom: string) => {
    return (
      <Typography
        style={{
          fontSize: `max(${width / 40}px, 16px)`,
          fontWeight: 'bold',
          color: COLORS.FONT,
          letterSpacing: '5px',
          marginBottom: marginBottom,
        }}
      >
        {text}
      </Typography>
    );
  };

  /**
   * 説明文をfor文で回して表示させるための処理
   * @param text テキスト
   * @returns
   */
  const messageText = (text: string) => {
    const items = [];
    for (let i = 0; i < t(text).length; i++) {
      items.push(
        <li key={i.toString()}>
          <ScrollReveal
            option={{
              duration: DURATION.PHRASE,
              reset: false,
              opacity: 0,
              delay: (i + 4) * DELAYTIME.PHRASE,
            }}
          >
            <Typography style={messageTypo()}>{t(text)[i]}</Typography>
          </ScrollReveal>
        </li>
      );
    }
    return <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>{items}</ul>;
  };

  return (
    <React.Fragment>
      <div
        style={{
          maxWidth: '1920px',
          padding: isMobile ? '100px 24px 0px' : '0px 24px',
          margin: 'auto',
          overflow: 'hidden',
        }}
      >
        <ThemeProvider theme={apptheme}>
          {/* MISSIONとVALUE */}
          <div
            style={{
              position: 'relative',
              marginTop: `${width / 10}px`,
              paddingBottom: `${width / 20}px`,
              minHeight: `${width / 1.8}px`,
            }}
          >
            {imageSetting('aboutus_01', '30%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-60%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {/* MISSION */}
              {oneChacterByDisplay(t('aboutUs.MISSION'))}
              {corporatePhilosophy(t('aboutUs.発想と創造の継続'), `${width / 8.5}px`)}

              {/* VALUE */}
              {oneChacterByDisplay(t('aboutUs.VALUE'))}
              {corporatePhilosophy(t('aboutUs.揺るがない信頼'), '10px')}
              {corporatePhilosophy(t('aboutUs.当たり前を提供'), '10px')}
            </div>
          </div>

          {/* SLOGANとSPILIT */}
          <div
            style={{
              position: 'relative',
              paddingTop: `${width / 20}px`,
              paddingBottom: `${width / 10}px`,
            }}
          >
            {imageSetting('aboutus_02', '30%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {/* SLOGAN */}
              {oneChacterByDisplay(t('aboutUs.SLOGAN'))}
              {corporatePhilosophy(t('aboutUs.本当に必要なものを提供する'), `${width / 12.8}px`)}

              {/* SPILIT */}
              {oneChacterByDisplay(t('aboutUs.SPILIT'))}
              {corporatePhilosophy(t('aboutUs.新しいことへの探求をする'), '10px')}
              {corporatePhilosophy(t('aboutUs.やり遂げる力をもつ'), '10px')}
              {corporatePhilosophy(t('aboutUs.仲間を大切にする'), '10px')}
              {corporatePhilosophy(t('aboutUs.常識と非常識の両立'), '10px')}
            </div>
          </div>

          {/* 取り組み */}
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {oneChacterByDisplay(t('aboutUs.取り組み'), attemptAndFutureBusiness())}
          </div>

          {/* Small office */}
          <div
            style={{
              position: 'relative',
              minHeight: `${width / 1.8}px`,
            }}
          >
            {imageSetting('aboutus_03', '40%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {messageTitle(t('aboutUs.スモールオフィス'))}
              {messageText('aboutUs.スモールオフィス説明文')}
            </div>
          </div>

          {/* 電子書類 */}
          <div
            style={{
              position: 'relative',
              paddingTop: `${width / 20}px`,
              paddingBottom: `${width / 10}px`,
              minHeight: `${width / 1.8}px`,
            }}
          >
            {imageSetting('aboutus_04', '40%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {messageTitle(t('aboutUs.電子書類'))}
              {messageText('aboutUs.電子書類説明文')}
            </div>
          </div>

          <div
            style={{
              textAlign: 'center',
            }}
          >
            {oneChacterByDisplay(t('aboutUs.今後の事業展開'), attemptAndFutureBusiness())}
          </div>

          {/* 機械学習とドローン */}
          <div
            style={{
              position: 'relative',
              minHeight: `${width / 1.8}px`,
            }}
          >
            {imageSetting('aboutus_05', '30%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {/* 機械学習 */}
              {messageTitle(t('aboutUs.機械学習'))}
              {messageText('aboutUs.機械学習説明文')}
            </div>
          </div>

          <div
            style={{
              position: 'relative',
              paddingTop: `${width / 20}px`,
              paddingBottom: `${width / 20}px`,
            }}
          >
            {imageSetting('aboutus_06', '40%')}
            <div
              // 画面サイズが700以下まで小さくなった場合は、テキストの表示方法を左寄せにする
              style={
                width >= 700
                  ? {
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(-50%,-50%)',
                      textAlign: 'center',
                      margin: '0px 10px',
                    }
                  : {
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      fontSize: '24px',
                      color: 'white',
                      transform: 'translate(0%,-50%)',
                      textAlign: 'start',
                      margin: '0px 10px',
                    }
              }
            >
              {messageTitle(t('aboutUs.ドローン'))}
              {messageText('aboutUs.ドローン説明文')}
            </div>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
