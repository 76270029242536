import React from 'react';
import { isMobile } from 'react-device-detect';

import { Avatar, Card, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material';
import { useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/achievementConst';

import AchievementCardMobile from './achievementCardMobile';
import AvatarSetting from './avatarSetting';

type Props = {
  flexEndFlag: boolean;
  title: string;
  text: string;
  developerName: string;
  comment: string;
  color: string;
  you: string;
  ki: string;
  sei: string;
  tan: string;
  ketu: string;
};

/**
 * 開発支援の実績カード
 * @param props
 * @returns
 */
const HomeCardDevelop: React.FC<Props> = (props) => {
  // 画面サイズ取得
  const [width] = useWindowSize();
  // モバイル版または、画面が一定サイズ以下ならば、引数だけ渡して別のメソッドに入る
  if (isMobile || width < 1050) {
    return <AchievementCardMobile isDevelop={false} propsObj={props}></AchievementCardMobile>;
  }
  return (
    <Grid
      container
      item
      xs={6}
      style={props.flexEndFlag ? { alignItems: 'center', justifyContent: 'flex-end' } : {}}
    >
      <ScrollReveal
        option={
          props.flexEndFlag
            ? {
                origin: 'left',
                distance: '500px',
                duration: DURATION.CARD,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.CARD,
              }
            : {
                origin: 'right',
                distance: '500px',
                duration: DURATION.CARD,
                reset: false,
                opacity: 0,
                delay: DELAYTIME.CARD,
              }
        }
      >
        <Card
          style={{
            width: `${width / 4}px`,
            height: '250px',
            margin: '10px 10px',
            backgroundColor: COLORS.CARD_BACK,
          }}
          elevation={12}
        >
          <CardHeader
            titleTypographyProps={{
              fontSize: `max(${width / 91}px, 16px)`,
              textAlign: 'start',
            }}
            title={`${props.title.slice(1)}`}
            style={{
              backgroundColor: 'white',
              minHeight: `${width / 30}px`,
              alignItems: 'start',
            }}
            avatar={
              <Avatar
                component={Paper}
                elevation={3}
                sx={{ width: 32, height: 32 }}
                style={{
                  color: 'black',
                  backgroundColor: `${props.color}`,
                  marginRight: `max(-${width / 80}px, -10px)`,
                }}
              >
                {`${props.title.charAt(0)}`}
              </Avatar>
            }
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ textAlign: 'start' }}
            >
              {props.text}
            </Typography>

            <Card elevation={0} style={{ backgroundColor: COLORS.CARD_BACK }}>
              <CardHeader
                style={{ padding: '16px 0px 0px 0px', alignItems: 'flex-start' }}
                avatar={
                  <AvatarSetting
                    you={props.you}
                    ki={props.ki}
                    sei={props.sei}
                    tan={props.tan}
                    ketu={props.ketu}
                  ></AvatarSetting>
                }
              />
            </Card>
          </CardContent>
        </Card>
      </ScrollReveal>
    </Grid>
  );
};

export default HomeCardDevelop;
