export const COLORS = {
  BODY_BACK: '#f2f2f2',
  FONT: '#222',
};

export const DELAYTIME = {
  TEXT: 200,
  TABLE: 500,
};

export const DURATION = {
  TEXT: 1000,
  TABLE: 1000,
};
