import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { apptheme, useWindowSize } from 'common/utils';
import ScrollReveal from 'components/scrollReveal';
import { COLORS, DELAYTIME, DURATION } from 'const/companyInfoConst';
import { t } from 'i18next';

/**
 * 企業情報のページ
 * @returns
 */
const CompanyInfo: React.FC = () => {
  const [width] = useWindowSize();

  /**
   * 会社情報の文字スタイル
   * @returns
   */
  const infoTypo = () => {
    return {
      color: COLORS.FONT,
      fontSize: `max(${width / 80}px, 13px)`,
      padding: '5px',
    };
  };

  /**
   * 法人名部分の文字スタイル
   * @returns
   */
  const infoBoldTypo = () => {
    return {
      fontWeight: 'bold',
      fontSize: `max(${width / 80}px, 13px)`,
      padding: '5px',
    };
  };

  /**
   * 会社情報
   * @param text テキスト
   * @param property スタイル設定
   * @returns
   */
  const messageText = (text: string, property: React.CSSProperties = infoTypo()) => {
    const items = [];
    for (let i = 0; i < t(text).length; i++) {
      items.push(
        <li key={i.toString()}>
          <Typography style={property}>{t(text)[i]}</Typography>
        </li>
      );
    }
    return <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>{items}</ul>;
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: '1920px', padding: '0px 24px', margin: 'auto', overflow: 'hidden' }}>
        <ThemeProvider theme={apptheme}>
          <div style={{ marginTop: '128px' }}>
            <div
              style={{
                paddingTop: '30px',
                paddingBottom: '30px',
                backgroundColor: COLORS.BODY_BACK,
              }}
            >
              <Grid container item xs={12} style={{ paddingRight: `${width / 16}px` }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{
                    justifyContent: 'flex-end',
                    paddingRight: `${width / 32}px`,
                  }}
                >
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'left',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box
                      style={{
                        margin: '10px',
                      }}
                    >
                      {messageText('companyInfo.法人名見出し', infoBoldTypo())}
                      {messageText('companyInfo.法人情報見出し')}
                      <ul style={{ listStyle: 'none', paddingLeft: `${width / 40}px` }}>
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              console.log(`click dispatch link start`);
                              e.preventDefault(); // リンクのイベントを無効化
                              window.open(
                                `https://airspo.co.jp/dispatchInformation/DispatchInformation.pdf`
                              );
                              console.log(`click dispatch link end`);
                            }}
                          >
                            <Typography style={infoBoldTypo()}>
                              <>{t(`companyInfo.派遣情報リンク`)}</>
                            </Typography>
                          </a>
                        </li>
                      </ul>
                    </Box>
                  </ScrollReveal>
                </Grid>
                <Grid container item xs={6}>
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'right',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>
                      {messageText('companyInfo.法人名', infoBoldTypo())}
                      {messageText('companyInfo.法人情報')}
                    </Box>
                  </ScrollReveal>
                </Grid>
              </Grid>
              {/* グーグルマップ */}
              <div style={{ textAlign: 'center', padding: '30px 0px' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d988.2118201056725!2d130.39914919385242!3d33.5810340787561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35419182e2091d5d%3A0xf3eb35531f251654!2z44CSODEwLTAwMjIg56aP5bKh55yM56aP5bKh5biC5Lit5aSu5Yy66Jas6Zmi77yT5LiB55uu77yR77yW4oiS77yT77yVIOWkp-WxseODk-ODqw!5e0!3m2!1sja!2sjp!4v1570090066774!5m2!1sja!2sjp"
                  width={`${width / 2.1}`}
                  height={`${width / 2.8}`}
                ></iframe>
                {messageText('companyInfo.グーグルマップ情報')}
              </div>
            </div>

            <div
              style={{
                paddingTop: '30px',
                paddingBottom: '30px',
                backgroundColor: COLORS.BODY_BACK,
              }}
            >
              <Grid container item xs={12} style={{ paddingRight: `${width / 16}px` }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: 'flex-end', paddingRight: '40px' }}
                >
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'left',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>
                      {messageText('companyInfo.主要取引先見出し')}
                    </Box>
                  </ScrollReveal>
                </Grid>

                <Grid container item xs={6}>
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'right',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>
                      {messageText('companyInfo.主要取引先情報')}
                    </Box>
                  </ScrollReveal>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                paddingTop: '30px',
                paddingBottom: '30px',
                backgroundColor: COLORS.BODY_BACK,
              }}
            >
              <Grid container item xs={12} style={{ paddingRight: `${width / 16}px` }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: 'flex-end', paddingRight: '40px' }}
                >
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'left',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>
                      {messageText('companyInfo.売上推移見出し')}
                    </Box>
                  </ScrollReveal>
                </Grid>

                <Grid container item xs={6}>
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'right',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>{messageText('companyInfo.売上推移情報')}</Box>
                  </ScrollReveal>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                paddingTop: '30px',
                paddingBottom: '30px',
                backgroundColor: COLORS.BODY_BACK,
              }}
            >
              <Grid container item xs={12} style={{ paddingRight: `${width / 16}px` }}>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ justifyContent: 'flex-end', paddingRight: '40px' }}
                >
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'left',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>
                      {messageText('companyInfo.会社沿革見出し')}
                    </Box>
                  </ScrollReveal>
                </Grid>

                <Grid container item xs={6}>
                  <ScrollReveal
                    option={{
                      viewOffset: { top: 0, right: 0, bottom: -100, left: 0 },
                      origin: 'right',
                      distance: '500px',
                      duration: DURATION.TEXT,
                      reset: false,
                      opacity: 0,
                      delay: DELAYTIME.TEXT,
                    }}
                  >
                    <Box style={{ margin: '10px' }}>{messageText('companyInfo.会社沿革情報')}</Box>
                  </ScrollReveal>
                </Grid>
              </Grid>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default CompanyInfo;
