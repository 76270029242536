import React from 'react';
import { isMobile } from 'react-device-detect';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { useWindowSize } from 'common/utils';

/**
 * コンポーネントの引数
 */
type Props = {
  cell: string[];
  data: any;
};

/**
 * ニュース記事のコンポーネント
 * @param props 受け渡しパラメータ
 */
const News: React.FC<Props> = (props) => {
  const [width] = useWindowSize();

  return (
    <Card
      style={isMobile ? { width: '90%', margin: 'auto' } : { width: '70%', margin: 'auto' }}
      elevation={12}
    >
      <CardHeader />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {props.cell.map((row) => (
                  <TableCell key={row} style={{ fontSize: `max(${width / 80}px, 10px)` }}>
                    {row}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.map((body: { date: string; title: string }) => (
                <TableRow key={body.date}>
                  <TableCell style={{ fontSize: `max(${width / 91}px, 8px)` }}>
                    {body.date}
                  </TableCell>
                  <TableCell style={{ fontSize: `max(${width / 91}px, 8px)` }}>
                    {body.title}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default News;
