import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';

import Footer from 'components/footer';
import Header from 'components/header';
import ScrollToTop from 'components/scrollTop';
import AboutUs from 'pages/aboutUs';
import Achievement from 'pages/achievement';
import CompanyInfo from 'pages/companyInfo';
import Home from 'pages/home';
import InHouseService from 'pages/inHouseService';
import Product from 'pages/product';
import RecruitInfo from 'pages/recruitInfo';
import Service from 'pages/service';
import WebService from 'pages/webService';

import './style.css';

const direction = {
  '/': 'left',
  '/aboutUs': 'left',
  '/service': 'left',
  '/achievement': 'left',
  '/companyInfo': 'left',
  '/recruitInfo': 'left',
  '/product': 'left',
  '/webService': 'left',
  '/inHouseService': 'left',
  '*': 'left',
};

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  const timeout = { enter: 1000, exit: 1000 };
  const getDirection = (currentKey: string) =>
    direction[currentKey as keyof typeof direction] ?? 'left';

  // inHouseServiceのページのみ、フッターの制御方法を変更する
  const isFixedFooterPage = pathname === '/inHouseService';

  return (
    <>
      <Header></Header>
      <TransitionGroup component="div" className="App">
        <SwitchTransition mode="out-in">
          <CSSTransition key={pathname} timeout={timeout} classNames="fade">
            <div className={getDirection(pathname)}>
              <ScrollToTop />
              <Routes location={location}>
                <Route path="/" element={<Home />} />
                <Route path="/aboutUs" element={<AboutUs />} />
                <Route path="/service" element={<Service />} />
                <Route path="/achievement" element={<Achievement />} />
                <Route path="/companyInfo" element={<CompanyInfo />} />
                <Route path="/recruitInfo" element={<RecruitInfo />} />
                <Route path="/product" element={<Product />} />
                <Route path="/webService" element={<WebService />} />
                <Route path="/inHouseService" element={<InHouseService />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </TransitionGroup>
      <Footer fixedToBottom={isFixedFooterPage} />
    </>
  );
}

export default App;
