import React from 'react';
// import { isMobile } from 'react-device-detect';

// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import GameVideo from '../movie/KAMEDASH_movie.mp4';

/**
 * ゲーム表示(モバイル)
 * @returns
 */
const GameVideoCardMobile = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  React.useEffect(() => {
    videoRef.current?.play();
  }, []);

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Grid container item style={{ justifyContent: 'center' }}>
        <Card
          style={{
            maxWidth: '480px',
            maxHeight: 'auto',
            margin: '20px 10px',
          }}
          elevation={12}
        >
          <Grid item sm={12}>
            <Box sx={{ display: 'flex' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                {/* ゲームタイトル */}
                <Typography component="div" variant="h5">
                  KAMEDASH
                </Typography>
                {/* ゲーム説明 */}
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  style={{ paddingBottom: '20px' }}
                >
                  2D横スクロールシューティングゲーム
                </Typography>
                <Typography variant="body1" color="text.secondary" component="div">
                  矢印キー：キャラ移動(上下左右)
                </Typography>
                <Typography variant="body1" color="text.secondary" component="div">
                  スペースキー：弾発射(長押しで連射可)
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="div"
                  style={{ paddingTop: '20px' }}
                >
                  ボスまで辿り着き、撃破できるか…?!
                </Typography>
              </CardContent>
              {/* モバイル表示の場合は試遊できないため、ゲーム画面への遷移ボタンを非表示にしておく */}
              {/* {!isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                <IconButton
                  aria-label="play/pause"
                  href={`${process.env.PUBLIC_URL}/export/KAMEDASH.html`}
                  target={'_blank'}
                >
                  <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                </IconButton>
                <Typography variant="body1" color="text.secondary" component="div">
                  GAME PLAY
                </Typography>
              </Box>
            )} */}
            </Box>
          </Grid>

          <Grid item sm={12}>
            <video
              controls
              src={GameVideo}
              itemType="video/mp4"
              playsInline
              loop
              autoPlay
              muted
              ref={videoRef}
              width={320}
            >
              <p>Your browser does not support HTML5 video.</p>
            </video>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default GameVideoCardMobile;
